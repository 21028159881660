/** @jsx jsx */
import { Box, jsx, Grid, Heading } from 'theme-ui'
import { navigate } from 'gatsby-link'
import { FC, useEffect } from 'react'
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'
import ErrorFocus from '~/components/error-focus'
import { Button } from '~/components/button'
import FormField from '~/components/form-field'
import urls from '~/common/urls'
import { useCartContext } from '~/context/cart-context'
import { MailingAddressInput } from '~/@types/models'
import { errorCodes, shippingStates } from '~/utils/checkout'
import { useCustomerContext } from '~/context/customer-context'

type FormValues = {
  address1: string
  email: string
  address2?: string
  city: string
  company?: string
  country: string
  firstName: string
  lastName: string
  phone: string
  province: string
  zip: string
}

const initialValues: FormValues = {
  address1: '',
  city: '',
  email: '',
  country: 'United States',
  firstName: '',
  lastName: '',
  phone: '',
  province: '',
  zip: '',
}

type Props = {}

const LoadInitialData: FC = () => {
  const { checkout } = useCartContext()
  const { setValues } = useFormikContext()

  useEffect(() => {
    if (checkout) setValues({ ...checkout.shippingAddress, email: checkout.email })
  }, [checkout])

  return <Box />
}

const CheckoutForm: FC<Props> = () => {
  const { updateMailingAddress, updateCheckoutEmail } = useCartContext()
  const { customer } = useCustomerContext()

  const callback = async (values: FormValues, actions: FormikHelpers<any>) => {
    try {
      const userEmail = values.email
      delete values.email
      if (!customer) {
        await updateMailingAddress({ ...(values as MailingAddressInput), country: 'United States' })
      }
      await updateCheckoutEmail(userEmail)
      await navigate(urls.purchase.shipping)
    } catch (e) {
      if (e.message === errorCodes.INVALID_FOR_COUNTRY) {
        actions.setFieldError('zip', 'Invalid zip for a country')
      }
    }
  }

  return (
    <Formik onSubmit={callback} initialValues={initialValues}>
      {({ isSubmitting }) => (
        <Form>
          <LoadInitialData />
          <ErrorFocus />
          <Box as="section">
            <FormField label="Email" name="email" />
            <FormField
              label="Keep me up to date on news and exclusive offers"
              name="acceptsMarketing"
              as="checkbox"
            />
            <Heading
              sx={{
                mt: ['57px', null, '82px'],
                mb: ['33px', null, '49px'],
                fontSize: [4, null, 6],
                lineHeight: ['40px'],
              }}
            >
              Shipping Address
            </Heading>
            <Grid columns={[1, 2]} gap={[null, null, 32]}>
              <FormField label="First name" name="firstName" />
              <FormField label="Last name" name="lastName" />
            </Grid>
            <FormField label="Company (optional)" name="company" />
            <FormField label="Address" name="address1" />
            <FormField label="Apartment, suite, etc. (optional)" name="address2" />
            <FormField label="City" name="city" />
            <Grid columns={[1, null, 3]} gap={[null, null, 32]}>
              <FormField label="Country / Region" readOnly={true} name="country" />
              <FormField label="State" as="select" options={shippingStates} name="province" />
              <FormField label="ZIP Code" name="zip" />
            </Grid>
            <FormField label="Phone" name="phone" />
            <FormField
              label="Save this information for next time"
              name="saveInformation"
              as="checkbox"
            />
          </Box>

          <Grid
            columns={[1, 2]}
            sx={{
              mt: [null, null, 73],
            }}
          >
            <Box>
              <Button
                label="Return to cart"
                as="link"
                to={urls.purchase.cart}
                variant="buttons.link"
                arrowPosition="before"
                arrowType="left"
                sxProps={{
                  minWidth: 'auto',
                  width: ['100%', null, 'auto'],
                  px: 0,
                }}
              />
            </Box>
            <Box>
              <Button
                label={isSubmitting ? '...' : 'Continue to shipping'}
                disabled={isSubmitting}
                sxProps={{
                  width: '100%',
                }}
                type="submit"
              />
              {/*{serverError && <ErrorMessage error={serverError} />}*/}
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CheckoutForm
