/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui'
import { FC } from 'react'
import { Button } from '~/components/button'
import CheckoutForm from '~/containers/checkout-form'
import CheckoutFlowLayout from '~/components/checkout-flow-layout'
import urls from '~/common/urls'
import Summary from '~/components/checkout-flow-layout/summary'
import { CartDiscountForm } from '~/containers/cart-discount-form'
import CartListSimple from '~/components/cart-list-simple'
import { formatMoney } from '~/utils/format'
import { useCartContext } from '~/context/cart-context'
import { getDiscountsList } from '~/utils/checkout'

type Props = {}

const Checkout: FC<Props> = () => {
  const { checkout } = useCartContext()

  return (
    <CheckoutFlowLayout
      title="Checkout"
      main={
        <Box>
          <Text
            sx={{
              textAlign: ['center', 'left'],
              color: 'txt',
            }}
          >
            Already have an account?{' '}
            <Button
              label="Log in"
              as="link"
              to={`${urls.account.signIn}?redirect=checkout`}
              variant="buttons.link"
              sxProps={{
                textDecoration: 'underline',
                minWidth: 'auto',
                pl: 0,
              }}
            />
          </Text>
          <Box
            sx={{
              mt: ['46px'],
            }}
          >
            <CheckoutForm />
          </Box>
        </Box>
      }
      side={
        <Box>
          <Box
            sx={{
              mt: [37],
              borderBottom: t => `1px solid ${t.colors.border}`,
            }}
          >
            <CartListSimple />
          </Box>
          <CartDiscountForm />
          <Summary
            rows={[
              {
                label: 'Subtotal',
                value:
                  checkout && checkout.lineItemsSubtotalPrice
                    ? formatMoney(
                        checkout.lineItemsSubtotalPrice.amount,
                        checkout.lineItemsSubtotalPrice.currencyCode
                      )
                    : '$0',
              },
              {
                label: 'Shipping',
                value: checkout?.shippingLine
                  ? formatMoney(
                      checkout.shippingLine.price.amount,
                      checkout.shippingLine.price.currencyCode
                    )
                  : 'Calculated in next step',
              },
            ]}
            discountRows={checkout ? getDiscountsList(checkout) : []}
            total={
              checkout && checkout.totalPrice
                ? formatMoney(checkout.totalPrice.amount, checkout.totalPrice.currencyCode)
                : formatMoney(0)
            }
          />
        </Box>
      }
    />
  )
}

export default Checkout
