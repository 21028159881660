/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import Checkout from '~/templates/checkout'

type Props = {}

const CheckoutPage: FC<Props> = () => <Checkout />

export default CheckoutPage
